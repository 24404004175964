import React from 'react';
import Nav from '../Nav/Nav';


function Header (){

    return(
        <div className="headerr">

           <Nav />

        </div>
    );
}

export default Header;